const useFreeGridStyles = ({ theme, MAX_DEVICE_WIDTH }) => ({
  root: {
    "&.freegrid-container": {
      display: "grid",
      gridTemplateColumns: "var(--cols)",
      gridTemplateRows: "var(--rows)",
      gridGap: "0px",
      padding: "0px",
      height: "100%",
      position: "static",
      // for drag item visibility beyond section
      // overflow: "hidden",
      "& .freegrid-item, & .freegrid-box-item": {
        gridArea: "var(--gridArea)",
        left: "var(--left)",
        marginTop: "var(--marginTop)",
        marginLeft: `calc((100% - ${MAX_DEVICE_WIDTH}px) * 0.5)`,
        "&.active-drag": {
          pointerEvents: "none",
          "& *": {
            pointerEvents: "none",
          },
          zIndex: "9999 !important",
        },
        "&.inactive-drag": {
          zIndex: "var(--zIndex) !important",
        },
        "& .editor-blocker": {
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0,0,0,0)",
          border: 0,
          outline: 0,
          zIndex: 1000,
        },
        "&.type_box": {
          "& .editor-blocker": {
            zIndex: 100,
          },
        },
        "&.type_text": {
          minHeight: "fit-content !important",
          wordBreak: "break-all",
        },
        "&.enable-1, &.enable-2": {
          "&.type_text": {
            // for dark theme
            // pages needs no color
            "&.no-color": {
              backgroundColor: "auto",
              color: "#000",
              "& .signed-btn": {
                "& img": {
                  backgroundColor: "auto",
                },
              },
            },
          },
        },
        "&.enable-1:before, &.enable-2:before": {
          position: "absolute",
          content: "attr(placeholder)",
          top: "-22px",
          left: "-2px",
          padding: "2px 4px",
          backgroundColor: "#2563EB",
          color: "#FFF",
          fontSize: "12px",
          borderTopLeftRadius: "2px",
          borderTopRightRadius: "2px",
          width: "auto",
        },
        "&:hover": {
          "& .debug-info": {
            opacity: 1,
          },
        },
        "& .debug-info": {
          position: "absolute",
          top: "calc(100% + 8px)",
          pointerEvents: "none",
          left: 0,
          background: "rgba(0,0,0,0.2)",
          color: "red",
          width: "100%",
          padding: "6px",
          opacity: 0,
        },
        "&.enable-1": {
          "&.type_text": {
            "*": {
              "::selection": {
                backgroundColor: "transparent !important",
                color: "inherit !important",
              },
            },
          },
          "& .fgi_type_box": {
            "& .freegrid-item": {
              // pointerEvents: "none",
            },
          },
        },
        "&.enable-2": {
          "& .fgi_type_box": {
            "& .freegrid-item": {
              // pointerEvents: "auto",
            },
          },
          "& .fgi_type_form": {
            "& .default-toolbar": {
              display: "block",
            },
          },
          "& .fgi_type_image": {
            "& .visible-on-hover": {
              display: "none !important",
            },
          },
        },
        /** for element items */
        "& .fgi_type_image, & .fgi_type_video": {
          height: "100%",
          overflow: "hidden",
          "& .embed": {
            width: "100%",
            height: "100%",
            "& .embed-image-wrpr, & .embed-video-wrpr-in, & .element-empty-btn":
              {
                height: "100%",
              },
          },
        },
        "& .fgi_type_button": {
          height: "100%",
          "& .moreBtnShow": {
            display: "none",
          },
          "& .editor-btn-wrapper": {
            height: "100%",
            "& .editor-btn-wrapper-inner": {
              width: "100%",
              height: "100%",
              "& span": {
                width: "100%",
                height: "100%",
              },
              "& .btn": {
                width: "100%",
                height: "100%",
                "&.textAlign-left": {
                  justifyContent: "start",
                },
                "&.textAlign-right": {
                  justifyContent: "end",
                },
                "&.textAlign-center": {
                  justifyContent: "center",
                },
              },
            },
          },
        },
        "& .fgi_type_signature": {
          height: "100%",
          "& .signature-container": {
            height: "100%",
            "& .signature-btn-container": {
              height: "100%",
              display: "flex",
              flexDirection: "column",

              "& .sign-placeholder": {
                display: "flex", // remove hidden sign placeholder
                width: "100%",
                background: theme?.palette?.editor?.background,
                flexGrow: 1,
              },

              "& button": {
                margin: "6px 0px",
              },
            },
          },
        },
        "& .fgi_type_sign": {
          width: "100%",
          height: "100%",

          "& .signature-signed-wrapper": {
            width: "100%",
            height: "100%",

            "& .signature-signed-span": {
              width: "100%",
              height: "100%",

              "& .signed-btn": {
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",

                "& img": {
                  width: "100% !important",
                  maxWidth: "none !important",
                  height: "70% !important",
                  flexGrow: 1,
                },
              },
            },
          },
        },
        "& .fgi_type_box": {
          display: "grid",
          width: "100%",
          height: "var(--height)",
          gridTemplateColumns: "var(--cols)",
          gridTemplateRows: "var(--rows)",
          "& .freegrid-item": {
            marginLeft: "0px",
            // pointerEvents: "none",
          },
          "&.rnd-dragOver": {
            outline: "2px solid #116dff",
            "&:before": {
              content: '"Attach to Box"',
              position: "absolute",
              top: "-36px",
              left: 0,
              right: 0,
              margin: "0px auto",
              width: "fit-content",
              background: "#116dff",
              padding: "8px 12px",
              color: "#FFF",
              fontSize: "14px",
              borderRadius: "7px",
            },
          },
        },
        "& .fgi_type_form": {
          "& .form-field": {
            "& input": {
              caretColor: "auto !important",
            },
          },
          "& .form-wrapper": {
            padding: "0px !important",
          },
          "& legend": {
            paddingLeft: "16px",
            paddingTop: "16px",
            width: "calc(100% -16px) !important",
          },
        },
        "& .fgi_type_appHeader": {
          maxWidth: "100%",
          maxHeight: "100%",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          "& .app-h-wrpr": {
            width: "100%",
          },
          "& .MuiAppBar-root": {
            zIndex: 100,
          },
          "& .MuiToolbar-root": {
            paddingLeft: "0px !important",
            paddingRight: "0px !important",
            "& .app-drawer-btn": {
              marginRight: "0px",
              paddingTop: "12px",
              // width: "100%",
              "& svg": {
                // width: "100%",
                // height: "100%",
              },
            },
          },
        },
        "& .fgi_type_table": {
          "& table": {
            "&.readOnly": {
              tableLayout: "fixed",
              width: "100% !important",
            },
          },
          "& .tableToolBar": {
            right: "0px",
            left: "auto",
            top: "-34px",
            display: "flex",
            flexDirection: "row-reverse",
            "& .toolbtn.settings": {
              display: "none",
            },
            "& .toolbtn.remove": {
              display: "none",
            },
          },
        },
        "& .fgi_type_embedScript": {
          width: "100%",
          height: "100%",
          backgroundColor: "#FFF",
          overflow: "hidden",
        },
        "& .fgi_type_text": {
          "& .edt-headings": {
            margin: "0px",
          },
        },
        /** element toolbar hide */
        "& .element-toolbar": {
          display: "none",
        },
        [theme.breakpoints.between("xs", "md")]: {
          marginLeft: `calc((100% - 320px) * 0.5)`,
        },
      },
      "&.rnd-dragOver": {
        "&:before": {
          content: '"Attach to this Section"',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          margin: "0px auto",
          width: "fit-content",
          background: "#116dff",
          padding: "8px 12px",
          color: "#FFF",
          fontSize: "14px",
          borderRadius: "7px",
        },
      },
    },
  },
  sectionPopper: {
    zIndex: 1200,
    width: "40px",
    left: "-58px !important",
    borderRadius: "8px",
    "& .papper-root": {
      fontFamily: "sans-serif",
      boxShadow: "-4px -3px 12px 4px rgba(0, 0, 0, 0.12)",
      background: "#FFF",
      "& .MuiIconButton-root": {
        padding: "10px",
        color: "#000",
        background: "#FFF",
        "& svg": {
          width: "20px",
          height: "20px",
        },
        "&.active,&:hover ": {},
      },
    },
  },
  sectionSettingsPopper: {
    "&.style-settings-wrpr": {
      maxHeight: "650px",
      overflowY: "auto",
      overflowX: "hidden",
      padding: "8px 8px 12px 12px",
      marginBottom: "12px",
    },
  },
});

export default useFreeGridStyles;
