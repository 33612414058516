import React, { useState } from "react";
import { Transforms } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";
import { IconButton, Tooltip, Box, useTheme } from "@mui/material";
import MUIIcon from "../../common/StyleBuilder/fieldTypes/loadIcon";
import ButtonPopup from "./ButtonPopup";
import { actionButtonRedirect } from "../../service/actionTrigger";
import { WorkflowIcon } from "../../common/iconslist";
import {
  getTRBLBreakPoints,
  getBreakPointsValue,
  groupByBreakpoint,
} from "../../helper/theme";
import { handleLinkType, windowVar } from "../../utils/helper";
import LinkSettings from "../../common/LinkSettings";
import Icon from "../../common/Icon";
import { useEditorContext } from "../../hooks/useMouseMove";

const EditorButton = (props) => {
  const theme = useTheme();
  const { theme: appTheme } = useEditorContext();
  const { attributes, element, customProps, children } = props;
  const { readOnly, metadata, isMobile } = customProps;
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);
  const [edit, setEdit] = useState(false);
  const [openNav, setOpenNav] = useState(false);
  const [openMoreOptions, setOpenMoreOptions] = useState(false);

  const {
    label,
    bgColor,
    borderRadius,
    bannerSpacing,
    textColor,
    url,
    buttonLink,
    textSize,
    textAlign,
    fontFamily,
    textColorHover,
    bgColorHover,
    buttonIcon,
    iconPosition = "start",
    borderStyle,
    borderWidth,
    borderColor,
    alignment,
    xsHidden,
    openInNewTab,
  } = element;
  const { linkType, redirectOnURLResult, actionTrigger } = buttonLink || {};
  const { onClick } = actionTrigger || { options: [] };

  const isTrigger = linkType === "nextTrigger" || linkType === "prevTrigger";

  const refURl = isTrigger ? buttonLink?.url : url;

  const BtnIcon = buttonIcon ? buttonIcon : null;

  windowVar.lastButtonProps = element;

  const handleTrigger = async () => {
    if (!readOnly) {
      return;
    }

    if (metadata?.buttonLink?.handler) {
      const response = isTrigger ? linkType : "click";
      metadata.buttonLink.handler(response);
    } else if (redirectOnURLResult) {
      // call api and redirect based on api result
      const apiResult = await actionButtonRedirect(
        {},
        { url: buttonLink?.url }
      );
      window.open(apiResult, "_blank").focus();
    } else {
      const refUrl = buttonLink?.url
        ? buttonLink?.url.includes("http")
          ? buttonLink?.url
          : `//${buttonLink?.url}`
        : "Link";
      window.open(refUrl, "_blank").focus();
    }
  };

  const buttonProps = handleLinkType(
    refURl,
    linkType,
    true, // button functionalities have to work on both edit mode and normal mode
    openInNewTab,
    handleTrigger
  );

  const onMenuClick = (val) => () => {
    switch (val) {
      case "edit":
        setEdit(true);
        return;
      case "delete":
        Transforms.removeNodes(editor, { at: [...path] });
        return;
      case "nav":
        setOpenNav(true);
        return;
      default:
        return;
    }
  };

  const Toolbar = () => {
    const btnProps = handleLinkType(
      refURl,
      linkType,
      true,
      openInNewTab,
      handleTrigger
    );

    const hideOpenLink = linkType === "page" || !linkType;

    return !readOnly ? (
      <div
        className="element-toolbar hr"
        style={{
          width: "max-content",
          top: "-33px",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Tooltip title="Settings" arrow>
          <IconButton onClick={onMenuClick("edit")}>
            <Icon icon="pagesSettings" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Nav Settings" arrow>
          <IconButton onClick={onMenuClick("nav")}>
            <Icon icon="link" />
          </IconButton>
        </Tooltip>

        {hideOpenLink ? null : (
          <Tooltip title="Open Link" arrow>
            <Box
              sx={{
                display: "inline-flex",
                color: "rgba(0, 0, 0, 0.54)",
                borderRadius: "50% !important",
                border: "none !important",
              }}
              {...btnProps}
            >
              <Icon icon="openLinkIcon" />
            </Box>
          </Tooltip>
        )}
      </div>
    ) : null;
  };

  const onSave = (data) => {
    const updateData = { ...data };
    delete updateData.children;
    Transforms.setNodes(
      editor,
      {
        ...updateData,
      },
      {
        at: [...path],
      }
    );
    setEdit(false);
  };

  const onClose = () => {
    setEdit(false);
  };

  const tAlign = alignment || textAlign || "left";

  const btnSp = groupByBreakpoint(
    {
      borderRadius: {
        ...getBreakPointsValue(
          borderRadius || {},
          null,
          "overrideBorderRadius",
          true
        ),
      },
      padding: {
        ...getTRBLBreakPoints(bannerSpacing),
      },
    },
    theme
  );

  const pSp = groupByBreakpoint(
    {
      display: {
        xs: xsHidden ? "none" : "inline-block",
        lg: "inline-block",
      },
    },
    theme
  );
  const handleMoreBtn = () => {
    setOpenMoreOptions(!openMoreOptions);
  };

  return (
    <div
      className={`editor-btn-wrapper`}
      {...attributes}
      style={{
        textAlign: tAlign,
        "&:hover": {
          "& .moreBtnShow": {
            opacity: 1,
          },
        },
      }}
      contentEditable={false}
    >
      <Box
        component={"div"}
        className="editor-btn-wrapper-inner"
        sx={{
          ...pSp,
          "& .element-toolbar": {
            display: openMoreOptions ? "flex" : "none",
            alignItems: "center",
            "&:hover": {
              display: openMoreOptions ? "flex" : "none",
              "& .moreBtnShow": {
                opacity: 1,
              },
            },
            "& svg": {
              "& path": {
                stroke: appTheme?.palette?.text?.primary,
              },
            },
            "& button": {
              borderRadius: "50%",
              border: "none",
            },
          },
          position: "relative",
        }}
      >
        <span style={{ position: "relative", display: "inline-block" }}>
          <Box
            className={`btn textAlign-${tAlign}`}
            sx={{
              textDecoration: "none",
              background: bgColor || "rgb(30, 75, 122)",
              borderBlockStyle: "solid",
              borderColor: borderColor || "transparent",
              borderWidth:
                borderWidth !== undefined
                  ? borderWidth
                  : borderColor
                  ? "1px"
                  : "0px",
              ...btnSp,
              borderStyle: borderStyle || "solid",
              color: `${textColor || "#FFFFFF"}`,
              fontSize: textSize || "inherit",
              fontFamily: fontFamily || "PoppinsRegular",
              display: "inline-flex",
              alignItems: "center",
              position: "relative",
              "& .element-toolbar": {
                display: "none",
              },
              "&:hover": {
                color: `${textColorHover || textColor || "#FFFFFF"}`,
                background: bgColorHover || bgColor || "rgb(30, 75, 122)",
                "& .element-toolbar": {
                  display: "flex",
                },
              },
            }}
            {...buttonProps}
          >
            {BtnIcon && iconPosition === "start" && (
              <MUIIcon
                iconName={buttonIcon}
                style={{ paddingLeft: "4px", paddingRight: "4px" }}
                props={customProps}
              />
            )}
            {label || "My Button"}
            {BtnIcon && iconPosition === "end" && (
              <MUIIcon
                iconName={buttonIcon}
                style={{ paddingLeft: "4px", paddingRight: "4px" }}
                props={customProps}
              />
            )}
          </Box>
          {!readOnly && (
            <IconButton
              className={`moreBtnShow ${
                isMobile || openMoreOptions ? "activeBtnShow" : ""
              }`}
              sx={{
                position: "absolute",
                right: "-42px",
                stroke: "#fff",
                "& path": {
                  fill: openMoreOptions ? appTheme.palette.text.blueText : "",
                },
              }}
              onClick={handleMoreBtn}
            >
              <Icon icon="moreVertical" />
            </IconButton>
          )}
          {!readOnly && isTrigger ? (
            <IconButton className="workflow-icon-btn">
              <WorkflowIcon />
            </IconButton>
          ) : null}
          <Toolbar />
        </span>
      </Box>
      <div contentEditable={false} style={{ display: "none" }}>
        {children}
      </div>
      {edit && (
        <ButtonPopup
          element={element}
          onSave={onSave}
          onClose={onClose}
          onDelete={onMenuClick("delete")}
          customProps={customProps}
        />
      )}

      {openNav ? (
        <LinkSettings
          handleClose={() => setOpenNav(false)}
          onSave={({ linkType, navValue, openInNewTab }) => {
            onSave({
              buttonLink: { linkType, onClick },
              url: navValue,
              openInNewTab,
            });

            setOpenNav(false);
          }}
          navType={element?.buttonLink?.linkType}
          navValue={element?.url}
          openInNewTab={element?.openInNewTab}
          customProps={customProps}
          allowTrigger={true}
        />
      ) : null}
    </div>
  );
};

export default EditorButton;
